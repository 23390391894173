import React from'react';
import {Form, Icon, Input, Button, Checkbox, Modal, Row, Col, Tooltip, Cascader,Select, AutoComplete} from 'antd';
// import { StarOutlined, StarFilled, StarTwoTone } from '@ant-design/icons';

import {Link, Redirect} from 'react-router-dom';
import './App.css';
import 'antd/dist/antd.css';
import './config';
import moment from "moment";

const layout = {
    labelCol: {
        // offset: 4,
        span: 10,
    },
    wrapperCol: {
        // offset: 8,
        span: 4,
    },
};
const tailLayout = {
    wrapperCol: {
        offset: 10,
        span: 4,
    },
};

class Register extends React.Component{
    constructor(props){
        super(props)
        this.state = {
            // aa: '',//记录登录验证，后台返回的合法记录数
            // notExpired: 0,//为0，表示账号过期
            visible: false,//控制模式对话框是否可见
            modalcontext: '',

            cropname: null, //公司名
            username: null, //姓名
            signname: null, //登录名
            password: null, //登录密码
            // phone: null, //手机号码
            title: null, //职位
            email: null, //email

            phone:'',//手机号码
            verifycode:'',
            btntype:'primary',//验证码按钮
            btntext:'获取验证码',
            isDisabled:false,//验证码按钮
            currentTime: 180, //倒计时，发送验证码按钮
            yzmnum: '', //验证码
            expire: true, //验证码是否到期
            expireTime: 600,//验证码有效期10分钟
            newuser: null,//新注册的用户
            sendok: false,//验证码是否发送

            registerok:false,//已注册成功
        };
        // this.handleOnClick=this.handleOnClick.bind(this);
        // this.checkUser = this.checkUser.bind(this);
    }

    componentWillMount(){
//服务器渲染的唯一hook
    }

    componentDidMount(){
//可以调用setState，renderComponent
    }

    handleSubmit = e => {
        e.preventDefault();
        // let history = this.props.history;
        this.props.form.validateFields((err, values) => {
            if (!err) {
                console.log('Received values of form: ', values);
                let cropname, truename, loginname, password, phone, verifyCode, email ;

                values.cropname ? cropname = `${values.cropname}`
                    : cropname = '';
                values.truename ? truename = `${values.truename}`
                    : truename = '';
                values.loginname ? loginname = `${values.loginname}`
                    : loginname = '';
                values.password ? password = `${values.password}`
                    : password = '';
                values.phone ? phone = `${values.phone}`
                    : phone = '';
                values.verifyCode ? verifyCode = `${values.verifyCode}`
                    : verifyCode = '';
                values.email ? email = `${values.email}`
                    : email = '';

                this.setState({
                    cropname: cropname, //公司名
                    username: truename, //姓名
                    signname: loginname, //登录名
                    password: password, //登录密码
                    phone: phone, //手机号码
                    verifycode: verifyCode,//验证码
                    email: email, //email
                });
                // this.checkUser(username, password);
                this.checkDuplicateUserName(loginname);

            }
        });
    };

    //登录名重名检测
    checkDuplicateUserName=(username)=>{
        let filter = {
            object: {
                object: {}
            }
        };

        var preurl = "checkDuplicateUserName?id=123&username=";
        var url = global.constants.url + preurl + username;
        var getInformation = {
            method: "POST",
            headers: {
                "Content-Type": "application/json"
            },
            /* json格式转换 */
            body: JSON.stringify(filter)
        }
        fetch(url, getInformation)
            .then(response => response.json())
            .then(responseJson => {
                // 返回的数据 根据自己返回的json格式取值.
                // debugger;
                console.log('Subordinate:', responseJson);
                // Subordinateid.length = 0;
                if (responseJson.length > 0) {
                    this.setState({
                        visible: true,
                        modalcontext: '该登录名已存在，请换一个登录名！',
                    })
                } else {
                    this.addShiheCustomer(this.state.cropname,this.state.username,this.state.signname,this.state.password,this.state.phone,this.state.verifycode,this.state.email);
                }


            })

    };

    //生成Camunda表的组ID
    getCamundaGroupID = (n) => {
        var random = function() { // 生成10-12位不等的字符串
            return Number(Math.random().toString().substr(2)).toString(36); // 转换成十六进制
        }
        var arr = [];
        function createId() {
            var num = random();
            var _bool = false;
            arr.forEach(v => {
                if(v === num) _bool = true;
            });
            if(_bool) {
                createId();
            }else {
                arr.push(num);
            }
        }
        var i = 0;
        while(i < n) {
            createId();
            i++;
        }
        return arr;
    };

    //生成ID
    getID=(n)=>{
        var random = function() { // 生成10-12位不等的字符串
            return Number(Math.random().toString().substr(2)).toString(36); // 转换成十六进制
        }
        var arr = [];
        function createId() {
            var num = random();
            var _bool = false;
            arr.forEach(v => {
                if(v === num) _bool = true;
            });
            if(_bool) {
                createId();
            }else {
                arr.push(num);
            }
        }
        var i = 0;
        while(i < n) {
            createId();
            i++;
        }
        return arr;
    };

    //注册，新增用户
    addShiheCustomer=(cropname,username,signname,password,phone,verifycode,email)=>{
        let filter = {
            object: {
                object: {}
            }
        };

       //var preurl = "addShiheCustomer?userid=8888888888&cropid=123&croptype=Web端自行注册&days=365&cropaddress=注册时无需填&cropname=";
        var preurl = "addShiheCustomer?cropid=123&croptype=Web端自行注册&days=365&cropaddress=注册时无需填&cropname=";
        var and = "&";
        var contactman1="contactman=";//
        var email1="email=";
        var phone1="phone=";
        var loginname1="loginname=";
        var loginpassword1="loginpassword=";
        var licenseamount1="licenseamount=10";
        var licenseused1="licenseused=1";
        var licenseresidue1="licenseresidue=9";
        var userid1="userid=";
        var ids=this.getID(1);
        var userid = ids[0];

        var url = global.constants.url+preurl+cropname+and+contactman1+username+and+email1+email+and+phone1+phone+
        and+loginname1+signname+and+loginpassword1+password+and+licenseamount1+and+licenseused1+and+licenseresidue1+and+userid1+userid;
        var getInformation = {
            method: "POST",
            headers: {
                "Content-Type": "application/json"
            },
            /* json格式转换 */
            body: JSON.stringify(filter)
        }
        fetch(url, getInformation)
            .then(response => response.json())
            .then(responseJson => {
                // 返回的数据 根据自己返回的json格式取值.
                // debugger;
                console.log('注册新用户:', responseJson);
                this.addUserRoleLink(responseJson.loginuserid,responseJson.cropid,responseJson);

                var sxbid = this.getID(1);
                var swsbsj = '09:00:00';//上午上班时间
                var swxbsj = '12:00:00';//上午下班时间
                var xwsbsj = '13:30:00';//下午上班时间
                var xwxbsj = '18:00:00';//下午下班时间
                var remark = '';
                //初始设置上下班时间
                this.addDutyTime(sxbid[0],responseJson.cropid,responseJson.cropname,swsbsj,swxbsj,xwsbsj,xwxbsj,remark);
                // var myid =777;
                // var camundaGroupID = this.getCamundaGroupID(8);
                // var type = responseJson.cropid;
                // var rev =1;
                // var groupName = ['管理员','公司领导','部门经理','业务员','财务人员','跟单员','人事专员','其他'];
                // // var groupName = ['管理员','公司领导','部门经理'];
                // console.log('camundaGroupID:', camundaGroupID);
                // for(var i=0;i<groupName.length;i++){
                //     this.addCamundaGroup(camundaGroupID[i],groupName[i],rev,type);
                // }


                //Camunda表增加 组
                // for(var i=0;i<8;i++){
                //     if(i===0){
                //         var groupName1 = '管理员';
                //         var rev1 = 1;
                //         // var type1 = responseJson.cropid;
                //         // var camundaGroupID1 = this.getCamundaGroupID(Math.floor(Math.random()*10));
                //         // console.log('camundaGroupID1:', camundaGroupID1);
                //         this.addCamundaGroup(myid,groupName1,rev,type);
                //     } else if(i===2){
                //         var groupName2 = '公司领导';
                //         // var rev2 = 1;
                //         // var type2 = responseJson.cropid;
                //         // var camundaGroupID2 = this.getCamundaGroupID(Math.floor(Math.random()*10));
                //         this.addCamundaGroup(myid,groupName2,rev,type);
                //     }else if(i===3){
                //         var groupName3 = '部门经理';
                //         // var rev3 = 1;
                //         // var type3 = responseJson.cropid;
                //         // var camundaGroupID3 = this.getCamundaGroupID(Math.floor(Math.random()*10));
                //         this.addCamundaGroup(myid,groupName3,rev,type);
                //     }else if(i===4){
                //         var groupName4 = '业务员';
                //         var rev4 = 1;
                //         var type4 = responseJson.cropid;
                //         // var camundaGroupID4 = this.getCamundaGroupID(Math.floor(Math.random()*10));
                //         this.addCamundaGroup(myid,groupName4,rev,type);
                //     }else if(i===5){
                //         var groupName5 = '财务人员';
                //         var rev5 = 1;
                //         var type5 = responseJson.cropid;
                //         // var camundaGroupID5 = this.getCamundaGroupID(Math.floor(Math.random()*10));
                //         this.addCamundaGroup(myid,groupName5,rev,type);
                //     }else if(i===6){
                //         var groupName6 = '跟单员';
                //         var rev6 = 1;
                //         var type6 = responseJson.cropid;
                //         // var camundaGroupID6 = this.getCamundaGroupID(Math.floor(Math.random()*10));
                //         this.addCamundaGroup(myid,groupName6,rev,type);
                //     }else if(i===7){
                //         var groupName7 = '人事专员';
                //         var rev7 = 1;
                //         var type7 = responseJson.cropid;
                //         // var camundaGroupID7 = this.getCamundaGroupID(Math.floor(Math.random()*10));
                //         this.addCamundaGroup(myid,groupName7,rev,type);
                //     }else if(i===8){
                //         var groupName8 = '其他';
                //         var rev8 = 1;
                //         var type8 = responseJson.cropid;
                //         // var camundaGroupID8 = this.getCamundaGroupID(Math.floor(Math.random()*10));
                //         this.addCamundaGroup(myid,groupName8,rev,type);
                //     }
                //
                // }


            })

    };

    //初始设置上下班时间
    addDutyTime=(id,cropid,cropname,swsbsj,swxbsj,xwsbsj,xwxbsj,remark)=>{
        let filter = {
            object: {
                object: {}
            }
        };

        var preurl = "addDutyTime?id=";
        var and = "&";
        var cropid1="cropid=";
        var cropname1="cropname=";
        var swsbsj1="swsbsj=";
        var swxbsj1="swxbsj=";
        var xwsbsj1="xwsbsj=";
        var xwxbsj1="xwxbsj=";
        var url = global.constants.url+preurl+id+and+cropid1+cropid+and+cropname1+cropname+and+swsbsj1+swsbsj+and+swxbsj1+swxbsj+and+xwsbsj1+xwsbsj+and+xwxbsj1+xwxbsj;
        var getInformation = {
            method: "POST",
            headers: {
                "Content-Type": "application/json"
            },
            /* json格式转换 */
            body: JSON.stringify(filter)
        }
        fetch(url, getInformation)
            .then(response => response.json())
            .then(responseJson => {
                console.log('新增上下班时间：',responseJson);

            })

}

    //camunda表 新增组，即角色
    addCamundaGroup = (camundaGroupID,groupName,rev,type) =>{
        let filter = {
            object: {
                object: {}
            }
        };

        var preurl = "addCamundaGroup?id=";
        var and = "&";
        var name1="name=";
        var rev1="rev=";
        var type1="type="
        var url = global.constants.url+preurl+camundaGroupID+and+name1+groupName+and+rev1+rev+and+type1+type;
        var getInformation = {
            method: "POST",
            headers: {
                "Content-Type": "application/json"
            },
            /* json格式转换 */
            body: JSON.stringify(filter)
        }
        fetch(url, getInformation)
            .then(response => response.json())
            .then(responseJson => {
                // 返回的数据 根据自己返回的json格式取值.
                // debugger;
                // if (!responseJson){
                //     this.addCamundaGroup(camundaGroupID,groupName,rev,type);
                // }

            })
}

    //新增角色-用户绑定,不单个元素传值
    addUserRoleLink=(loginuserid,cropid,userinfo)=>{
        let filter = {
            object: {
                object: {}
            }
        };

        var preurl = "addUserRoleLink?roleidFk=1&arr=";
        var and = "&";
        var cropid1="cropid=";
        var url = global.constants.url+preurl+loginuserid+and+cropid1+cropid;
        var getInformation = {
            method: "POST",
            headers: {
                "Content-Type": "application/json"
            },
            /* json格式转换 */
            body: JSON.stringify(filter)
        }
        fetch(url, getInformation)
            .then(response => response.json())
            .then(responseJson => {
                // 返回的数据 根据自己返回的json格式取值.
                // debugger;
                console.log('addUserRoleLink:', responseJson);
                this.addRoleModuleLinkArr(responseJson.cropid,loginuserid,userinfo);
            })

    };

    //新增角色-模块绑定arr,不单个arr元素传值
    addRoleModuleLinkArr=(cropid,loginuserid,userinfo)=>{
        // const DefalutModuleID = [6, 7, 8, 9, 11,12,13,14,15];
        const DefalutModuleID = [1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20,21,22,23,24,25,26,27,28,29,30,31,32,33,34,35,36,37,38,39,40,41,42,43,44,45,46,47,48,49,50,51,52,53,54,55,56,57,58,59,60,61,62,63,64,65,66,67,68,69,70,71,72,73,74,75,76,77,79,80,81,82,83,84,85,86,87,88,89,90,91,92,93,94,95,96,97,98,99,100];
        let filter = {
            object: {
                object: {}
            }
        };

        var preurl = "addRoleModuleLinkArr?roleidFk=1&arr=";
        var and = "&";
        var arr1 = DefalutModuleID.toString();
        var cropid1="cropid=";

        var url = global.constants.url+preurl+arr1+and+cropid1+cropid;
        var getInformation = {
            method: "POST",
            headers: {
                "Content-Type": "application/json"
            },
            /* json格式转换 */
            body: JSON.stringify(filter)
        }
        fetch(url, getInformation)
            .then(response => response.json())
            .then(responseJson => {
                // 返回的数据 根据自己返回的json格式取值.
                // debugger;
                console.log('addRoleModuleLinkArr:', responseJson);
                if (responseJson) {
                    // this.setState({
                    //     visible: true,
                    //     registerok: true,//已注册成功,跳转到注册页
                    //     modalcontext: '已注册成功，请登录！',
                    // });

                    // this.gotologin();

                    // var myid =777;
                    /////////////////////////////
                    // var camundaGroupID = this.getCamundaGroupID(27);
                    var camundaGroupID = this.getCamundaGroupID(1);
                    // var type = responseJson.cropid;
                    // var rev =1;
                    // var groupName = ['管理员','公司领导','部门经理','业务员','财务人员','跟单员','人事专员','其他'];
                    // var groupName = ['管理员','公司领导','部门经理'];
                    console.log('camundaGroupID:', camundaGroupID);
                    // for(var i=0;i<groupName.length;i++){
                    //     this.addCamundaGroup(camundaGroupID[i],groupName[i],rev,type);
                    // }
                    /////////////////////////////
                    var type = cropid;
                    var rev =1;
                    // var groupName = ['管理员','董事长','总经理','副总经理','财务总监','财务经理','出纳','销售总监','销售经理','跟单员','人事总监','人事经理','技术总监','工程师',
                    //     '商务总监','商务经理','采购总监','采购专员','品质主管','品质专员','仓库主管','仓库员','项目总监','项目经理','生产总监','生产员','区域经理'];
                    // var groupName = ['管理员','公司领导','部门经理'];
                    var groupName = ['管理员'];

                    for(var i=0;i<groupName.length;i++){
                        this.addCamundaGroup(camundaGroupID[i],groupName[i],rev,type);
                    }

                    console.log('userinfo:', userinfo);
                    //camunda 增加用户
                    this.addCamundaUser(loginuserid,userinfo.loginname,userinfo.loginpassword,userinfo.email,userinfo.cropid,camundaGroupID[0]);



                } else {
                    this.setState({
                        visible: true,
                        modalcontext: '未注册成功，请重新注册！',
                    });
                }
            })
    };

    //camunda 增加用户
    addCamundaUser=(loginuserid,loginname,loginpassword,email,cropid,camundaGroupID)=>{
        let filter = {
            object: {
                object: {}
            }
        };

        var preurl = "addCamundaUser?id=";
        var and = "&";
        var firstname1 = "firstname=";
        var lastname1 = "lastname=";
        var email1 = "email=";
        var pwd1 = "pwd=";

        var url = global.constants.url+preurl+loginuserid+and+firstname1+loginname+and+email1+email+and+pwd1+loginpassword;
        var getInformation = {
            method: "POST",
            headers: {
                "Content-Type": "application/json"
            },
            /* json格式转换 */
            body: JSON.stringify(filter)
        }
        fetch(url, getInformation)
            // .then(response => response.json())
            .then(responseJson => {
                // 返回的数据 根据自己返回的json格式取值.

                //camunda 将用户添加到租户下
                this.saveCamundaTenantUserMembership(cropid,loginuserid,camundaGroupID);

            })
    }

    //camunda 将用户添加到租户下
    saveCamundaTenantUserMembership=(cropid,loginuserid,camundaGroupID)=>{
        let filter = {
            object: {
                object: {}
            }
        };

        var preurl = "saveCamundaTenantUserMembership?tenantId=";
        var and = "&";
        var userId1 = "userId=";

        var url = global.constants.url+preurl+cropid+and+userId1+loginuserid;
        var getInformation = {
            method: "POST",
            headers: {
                "Content-Type": "application/json"
            },
            /* json格式转换 */
            body: JSON.stringify(filter)
        }
        fetch(url, getInformation)
            // .then(response => response.json())
            .then(responseJson => {
                // 返回的数据 根据自己返回的json格式取值.

                //camunda 将用户(注册者)添加到组（"管理员"这个组）
                // this.createCamundaMembership(loginuserid, camundaGroupID);

                //camunda 将组添加到租户下
                this.saveCamundaTenantGroupMembership(cropid,loginuserid,camundaGroupID);
            })
    }

    //camunda 将组添加到租户下
    saveCamundaTenantGroupMembership(cropid,loginuserid,camundaGroupID){
        let filter = {
            object: {
                object: {}
            }
        };

        var preurl = "saveCamundaTenantGroupMembership?tenantId=";
        var and = "&";
        var groupId1 = "groupId=";

        var url = global.constants.url+preurl+cropid+and+groupId1+camundaGroupID;
        var getInformation = {
            method: "POST",
            headers: {
                "Content-Type": "application/json"
            },
            /* json格式转换 */
            body: JSON.stringify(filter)
        }
        fetch(url, getInformation)
            // .then(response => response.json())
            .then(responseJson => {
                // 返回的数据 根据自己返回的json格式取值.
                // debugger;

                //camunda 将用户(注册者)添加到组（"管理员"这个组）
                this.createCamundaMembership(loginuserid, camundaGroupID);
            })
    }

    //camunda 将用户添加到组
    createCamundaMembership=(loginuserid, camundaGroupID)=>{
        let filter = {
            object: {
                object: {}
            }
        };

        var preurl = "createCamundaMembership?userId=";
        var and = "&";
        var groupId1 = "groupId=";

        var url = global.constants.url+preurl+loginuserid+and+groupId1+camundaGroupID;
        var getInformation = {
            method: "POST",
            headers: {
                "Content-Type": "application/json"
            },
            /* json格式转换 */
            body: JSON.stringify(filter)
        }
        fetch(url, getInformation)
            // .then(response => response.json())
            .then(responseJson => {
                // 返回的数据 根据自己返回的json格式取值.
                // debugger;

                this.setState({
                    visible: true,
                    registerok: true,//已注册成功,跳转到注册页
                    modalcontext: '已注册成功，请登录！',
                });
            })
    };

    handleModalOk = e => {
        console.log(e);
        if(this.state.sendok){
            this.setState({
                visible: false,
                // btntype:'primary',
                isDisabled:true,
                sendok:false,
                // yzmnum: '', //验证码置空
                // expire: true, //验证码到期
            });

        }else if(this.state.registerok){
            this.props.history.push('/Login1');
            this.setState({
                registerok: false,
                // btntype:'primary',
                // isDisabled:false,
                // yzmnum: '', //验证码置空
                // expire: true, //验证码到期
            });

        }else {
            this.setState({
                visible: false,
                btntype:'primary',
                isDisabled:false,
                yzmnum: '', //验证码置空
                expire: true, //验证码到期
            });
        }
    };

    handleModalCancel = e => {
        console.log(e);
        if(this.state.sendok){
            this.setState({
                visible: false,
                // btntype:'primary',
                isDisabled:true,
                sendok:false,
                // yzmnum: '', //验证码置空
                // expire: true, //验证码到期
            });

        }else {
            this.setState({
                visible: false,
                btntype:'primary',
                isDisabled:false,
                yzmnum: '', //验证码置空
                expire: true, //验证码到期
            });
        }
    };

    //获取用户输入的手机号码
    getphone =(event)=>{
        this.setState({
            phone:event.target.value
        });

    };

    getVerifyCode = ()=>{
        this.setState({
            btntype:'text',
            isDisabled:true,
            yzmnum: '', //验证码置空
            expire: false, //验证码没到期
        });

        var phone = this.state.phone;
        var currentTime = this.state.currentTime; //把手机号跟倒计时值变例成js值
        var expireTime = this.state.expireTime; //验证码有效期10分钟


        // var warn = null; //warn为当手机号为空或格式不正确时提示用户的文字，默认为空
        if (phone === '') {
            // warn = "号码不能为空";
            this.setState({
                visible: true,
                modalcontext: '手机号码不能为空！',
            })
        } else if (phone.trim().length != 11 || !/^1[3|4|5|6|7|8|9]\d{9}$/.test(phone)) {
            // warn = "手机号格式不正确";
            this.setState({
                visible: true,
                modalcontext: '手机号格式不正确！',
            })
        } else{
            let filter = {
                object: {
                    object: {}
                }
            };

            var preurl = "sms?phone=";
            var url = global.constants.url + preurl + this.state.phone;
            var getInformation = {
                method: "POST",
                headers: {
                    "Content-Type": "application/json"
                },
                /* json格式转换 */
                body: JSON.stringify(filter)
            }
            fetch(url, getInformation)
                .then(response => response.json())
                .then(responseJson => {
                    // 返回的数据 根据自己返回的json格式取值.
                    // debugger;
                    // console.log('短信返回:', responseJson);
                    this.setState({
                        verifycode:responseJson
                    });

                    if(responseJson!==''){
                        if(responseJson==='failsend'){
                            this.setState({
                                visible: true,
                                modalcontext: '验证码发送失败，请重新获取验证码！',
                            })
                        }else {
                            this.setState({
                                visible: true,
                                modalcontext: '短信验证码已发送!',
                                sendok:true,
                            })
                        }

                    }

                });
            var that = this;
            //设置180秒的倒计时，发送验证码按钮
            var interval = setInterval(function () {
                // var that = this;
                currentTime--; //每执行一次让倒计时秒数减一
                // that.setData({
                //     text: currentTime + '秒', //按钮文字变成倒计时对应秒数
                // })
                that.setState({
                    btntext: currentTime + '秒', //按钮文字变成倒计时对应秒数
                })
                //如果当秒数小于等于0时 停止计时器 且按钮文字变成重新发送 且按钮变成可用状态 倒计时的秒数也要恢复成默认秒数 即让获取验证码的按钮恢复到初始化状态只改变按钮文字
                if (currentTime <= 0) {
                    clearInterval(interval)

                    that.setState({
                        // visible: false,
                        btntype:'primary',
                        isDisabled:false,
                        // yzmnum: '', //验证码置空
                        // expire: true, //验证码到期
                        currentTime: 180,
                        btntext:'重新发送',
                    });
                }
            }, 1000);

            //设置15分钟的倒计时，验证码有效期
            var interval1 = setInterval(function () {
                // var that = this;
                expireTime--; //验证码过期时间

                //如果当秒数小于等于0时 停止计时器 且按钮文字变成重新发送 且按钮变成可用状态 倒计时的秒数也要恢复成默认秒数 即让获取验证码的按钮恢复到初始化状态只改变按钮文字
                if (expireTime <= 0) {
                    clearInterval(interval1)
                    that.setState({
                        expireTime: 600,
                        expire: true, //验证码已到期
                    });
                }
            }, 1000);

        }


    };



    render(){
        //Form.create 包装的组件会自带this.props.form属性，该属性提供了一系列API，包括以下4个
        //getFieldDecorator用于和表单进行双向绑定
        //isFieldTouched判断一个输入控件是否经历过 getFieldDecorator 的值收集时机 options.trigger(收集子节点的值的时机，默认时onChange)
        //getFieldError获取某个输入控件的 Error
        //获取一组输入控件的 Error ，如不传入参数，则获取全部组件的 Error
        const {getFieldDecorator, getFieldsError, getFieldError, isFieldTouched} = this.props.form;

        const cropnameError = isFieldTouched('cropname') && getFieldError('cropname');
        const truenameError = isFieldTouched('truename') && getFieldError('truename');
        const loginnameError = isFieldTouched('loginname') && getFieldError('loginname');
        const passwordError = isFieldTouched('password') && getFieldError('password');
        const phoneError = isFieldTouched('phone') && getFieldError('phone');
        const verifyCodeError = isFieldTouched('verifyCode') && getFieldError('verifyCode');
        const emailError = isFieldTouched('email') && getFieldError('email');
        // var btntype=this.state.btntype;
//validateStatus为校验状态，如不设置，则会根据校验规则自动生成，可选：'success' 'warning' 'error' 'validating'

        return(
            <div>
                <div>
                    <div className="box">
                        {/*<div>*/}
                            {/*<img className="imgwidth" src={require("./shihe.png")} alt=""/>*/}
                        {/*</div>*/}
                        <div className="wenzi">>
                            <p>CRM软件</p>
                        </div>

                    </div>


                    <Form onSubmit={this.handleSubmit}
                          {...layout}
                          name="basic"
                        >
                        <div className="denglu" style={{ textAlign: 'center' }}>注册</div>

                        <Form.Item label="公司名称" validateStatus={cropnameError ? 'error' : ''}>
                            {getFieldDecorator('cropname', {
                                rules: [{required: true, message: '公司名称是必填项!'}],
                            })(
                                <Input
                                    prefix={<Icon type="appstore" style={{color: 'rgba(0,0,0,.25)'}}/>}
                                    placeholder="请输入您的公司名称"
                                />,
                            )}
                        </Form.Item>

                        <Form.Item label="姓名" validateStatus={truenameError ? "error" : ''}>
                            {getFieldDecorator('truename', {
                                rules: [{required: true, message: '真实姓名是必填项!'}],
                            })(
                                <Input
                                    prefix={<Icon type="user" style={{color: 'rgba(0,0,0,.25)'}}/>}
                                    placeholder="请输入您的真实姓名"
                                />,
                            )}
                        </Form.Item>

                        <Form.Item label="登录名" validateStatus={loginnameError ? "error" : ''}>
                            {getFieldDecorator('loginname', {
                                rules: [{required: true, message: '登录名是必填项!'}],
                            })(
                                <Input
                                    prefix={<Icon type="user" style={{color: 'rgba(0,0,0,.25)'}}/>}
                                    placeholder="请输入您的登录名"
                                />,
                            )}
                        </Form.Item>

                        <Form.Item label="登录密码" validateStatus={passwordError ? "error" : ''}>
                            {getFieldDecorator('password', {
                                rules: [{required: true, message: '登录密码是必填项!'}],
                            })(
                                <Input
                                    prefix={<Icon type="lock" style={{color: 'rgba(0,0,0,.25)'}}/>}
                                    placeholder="请输入您的登录密码"
                                />,
                            )}
                        </Form.Item>

                        <Form.Item label="手机号码" validateStatus={phoneError ? "error" : ''}>
                            {getFieldDecorator('phone', {
                                rules: [{required: true, message: '手机号码是必填项!'}],
                            })(
                                <Input
                                    prefix={<Icon type="phone" style={{color: 'rgba(0,0,0,.25)'}}/>}
                                    placeholder="请输入您的手机号码"
                                    onChange={(event)=>this.getphone(event)}
                                />,
                            )}
                        </Form.Item>

                        <Form.Item label="验证码" validateStatus={verifyCodeError ? "error" : ''}>
                            <Row gutter={8}>
                                <Col span={13}>
                                    {/*<Form.Item  >*/}
                                        {getFieldDecorator('verifyCode', {
                                            rules: [{required: true, message: '验证码是必填项!'}],
                                        })(
                                            <Input
                                                prefix={<Icon type="lock" style={{color: 'rgba(0,0,0,.25)'}}/>}
                                                placeholder="输入验证码"
                                            />,
                                        )}
                                    {/*</Form.Item>*/}
                                </Col>
                                <Col span={10}>
                                    <Button type={this.state.btntype}  disabled={this.state.isDisabled} onClick={()=>this.getVerifyCode()}>{this.state.btntext}</Button>
                                </Col>
                            </Row>
                        </Form.Item>

                        <Form.Item label="E-mail" validateStatus={emailError ? "error" : ''}>
                            {getFieldDecorator('email', {
                                rules: [{required: false, message: 'E-mail不是必填项!'}],
                            })(
                                <Input
                                    prefix={<Icon type="mail" style={{color: 'rgba(0,0,0,.25)'}}/>}
                                    placeholder="请输入您的E-mail"
                                />,
                            )}
                        </Form.Item>


                        <Form.Item {...tailLayout}>

                            {/*{getFieldDecorator('remember', {*/}
                            {/*valuePropName: 'checked',*/}
                            {/*initialValue: true,*/}
                            {/*})(<Checkbox>记住我</Checkbox>)}*/}

                            {/*<a className="login-form-forgot" href="">*/}
                            {/*忘记密码*/}
                            {/*</a>*/}

                            <Button type="primary" htmlType="submit" className="login-form-button">
                                立即注册
                            </Button>
                            {/*<a href="">立即注册</a> <a href="">忘记密码</a>*/}
                            <Link to={"/Login1"}>去登录</Link>
                            <Link to={"/"} style={{ marginLeft: 10 }}>去主页</Link>
                        </Form.Item>
                    </Form>

                </div>

                <div className="footer" style={{ textAlign: 'center' }}>
                    {/*<div>业务咨询：0755-61300562</div>*/}
                    {/*<div>客服QQ：260815998，E-mail：260815998@qq.com</div>*/}
                    <a href="http://www.beian.miit.gov.cn/">ICP证号：粤ICP备19140753号</a>
                    <div>©2019-2024 深圳市时和科技有限公司</div>
                </div>

                <div>
                    <Modal
                        title="提示"
                        visible={this.state.visible}
                        onOk={this.handleModalOk}
                        onCancel={this.handleModalCancel}
                        okText="确定"
                        cancelText="取消"
                    >
                        <p>{this.state.modalcontext}</p>
                    </Modal>
                </div>
            </div>


        );
    }
}

const Register1 = Form.create({name: 'normal_login'})(Register);
export default Register1
