import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import 'antd/dist/antd.css';
// import App from './App';
import * as serviceWorker from './serviceWorker';
import Login from './Login';
import Register from './Register';
import Home from './Home';
import HomeKe from './HomeKe';
import {AsyncApp} from './HomeKe';

import Loadable from 'react-loadable';
import {BrowserRouter, Route,Switch, Redirect} from 'react-router-dom';
// import zhCN from 'antd/es/locale/zh_CN';
// import { ConfigProvider } from 'antd';
// import locale from 'antd/es/locale/zh_CN';
// import moment from 'moment';
// import 'moment/locale/zh-cn';

// import {ConfigProvider} from "antd";

{/*<App />,*/}

// ReactDOM.render(<App />, document.getElementById('root'));
//第一种代码分割方法
function asyncComponent(importComponent) {
    class AsyncComponent extends React.Component {
        constructor(props) {
            super(props);

            this.state = {
                component: null
            };
        }

        async componentDidMount() {
            const { default: component } = await importComponent();

            this.setState({
                component: component
            });
        }

        render() {
            const C = this.state.component;

            return C ? <C {...this.props} /> : null;
        }
    }

    return AsyncComponent;
}

//第二种代码分割方法
//30秒
const MyLoadingComponent = ({isLoading, error}) => {
    // Handle the loading state
    if (isLoading) {
        return (
            <div>
                <div className="wenzi4">页面加载中，请稍等30秒...</div>
                {/*<div className="wenzi4">请登录或注册，以便看到数据！（可使用体验账号登录）</div>*/}
            </div>
        );
    }
    // Handle the error state
    else if (error) {
        return (
            <div>
                <div className="wenzi4">页面加载中，请稍等30秒...</div>
                {/*<div className="wenzi4">请登录或注册，以便看到数据！（可使用体验账号登录）</div>*/}
            </div>
        );
    }
    else {
        return null;
    }
};






// const AsyncHome = asyncComponent(() => import("./Home"));
// const AsyncApp = asyncComponent(() => import("./App"));


///////////////////////////////////
//第二种代码分割方法
// const MyLoadingComponent = ({isLoading, error}) => {
//     // Handle the loading state
//     if (isLoading) {
//         return <div className="wenzi4">页面加载中，请稍等...</div>;
//     }
//     // Handle the error state
//     else if (error) {
//         return <div className="wenzi4">页面加载中，请稍等...</div>;
//     }
//     else {
//         return null;
//     }
// };

// const AsyncLogin = Loadable({
//     loader: () => import("./Login"),
//     loading: MyLoadingComponent,
//     // delay: 300, // 0.3 seconds
// });

// const AsyncApp = Loadable({
//     loader: () => import("./App"),
//     loading: MyLoadingComponent
//     // delay: 300, // 0.3 seconds
// });

const AsyncHome = Loadable({
    loader: () => import("./Home"),
    loading: MyLoadingComponent
    // delay: 300, // 0.3 seconds
});

// const AsyncLogin = asyncComponent(() => import("./Login"));
// const AsyncRegister = asyncComponent(() => import("./Register"));
// const AsyncIntroduce = asyncComponent(() => import("./Introduce"));

const AsyncLogin = Loadable({
    loader: () => import("./Login"),
    loading: MyLoadingComponent
    // delay: 300, // 0.3 seconds
});

const AsyncRegister = Loadable({
    loader: () => import("./Register"),
    loading: MyLoadingComponent
    // delay: 300, // 0.3 seconds
});

const AsyncIntroduce = Loadable({
    loader: () => import("./Introduce"),
    loading: MyLoadingComponent
    // delay: 300, // 0.3 seconds
});


// const AsyncRegister = Loadable({
//     loader: () => import("./Register"),
//     loading: MyLoadingComponent
//     // delay: 300, // 0.3 seconds
// });

// class index extends React.Component {
//     constructor(props) {
//         super(props)
//     }
//
//     componentWillMount() {
//
//     }
//
//     componentDidMount() {
//         AsyncHome.preload();
//         AsyncApp.preload();
//
//     }
// }

ReactDOM.render(
    <BrowserRouter>
        <Switch>
            {/*<Route path={"/Login1"} component={Login}/>*/}
            {/*<Route path={"/"} component={App}/>*/}
            <Route path={"/Login1"} component={AsyncLogin}/>
            <Route path={"/Register1"} component={AsyncRegister}/>
            <Route path={"/App1"} component={AsyncApp}/>
            <Route path={"/Introduce"} component={AsyncIntroduce}/>
            {/*<Route path="/" component={AsyncHome} />*/}

            {/*<Route path="/Home" component={AsyncHome} />*/}
            {/*<Route path={"/"} component={AsyncApp}/>*/}
            <Route path="/" component={HomeKe} />

            {/*<Redirect to={"/Login1"}/>*/}
        </Switch>
    </BrowserRouter>,
    document.getElementById('root')
);
// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
