import React from 'react';
import {Link, Redirect} from 'react-router-dom';
// import App from './App';
import './App.css';
import 'antd/dist/antd.css';
import {Form, Icon, Input, Button, Checkbox, Modal,Row,Col} from 'antd';
import './config';

const Subordinateid = [];
const layout = {
    labelCol: {
        // offset: 4,
        span: 10,
    },
    wrapperCol: {
        // offset: 8,
        span: 4,
    },
};
const tailLayout = {
    wrapperCol: {
        offset: 10,
        span: 4,
    },
};

class Login extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            aa: '',//记录登录验证，后台返回的合法记录数
            notExpired: 0,//为0，表示账号过期
            visible: false,//控制模式对话框是否可见
            modalcontext: '',
        };
        // this.handleOnClick=this.handleOnClick.bind(this);
        this.checkUser = this.checkUser.bind(this);
    }

    componentDidMount() {
        // this.getSubordinate(global.constants.userid);

    }

    componentWillUnmount() {

    }



    handleSubmit = e => {
        e.preventDefault();
        // let history = this.props.history;
        this.props.form.validateFields((err, values) => {
            if (!err) {
                console.log('Received values of form: ', values);

                let username, password;
                values.username ? username = `${values.username}`
                    : username = '';
                values.password ? password = `${values.password}`
                    : password = '';

                this.setState({
                    username: username,
                    password: password
                })
                this.checkUser(username, password);

            }
        });
    };

    //从后台查询是否有此用户
    checkUser(username, password) {
        /* 查询数据的格式 */
        let filter = {
            object: {
                object: {}
            }
        };


        var preurl = "checkUser1?username=";
        var and = "&";
        var password1 = "password=";

        var url = global.constants.url + preurl + username + and + password1 + password;
        var getInformation = {
            method: "POST",
            headers: {
                "Content-Type": "application/json"
            },
            /* json格式转换 */
            body: JSON.stringify(filter)
        }
        fetch(url, getInformation)
            .then(response => response.json())
            .then(responseJson => {
                // 返回的数据 根据自己返回的json格式取值.
                // debugger;
                console.log(responseJson);

                var count = Object.keys(responseJson).length;//登录验证，返回合法的记录数，=1为合法
                global.constants.count1 = count;//设置到全局变量中，给主页引用，以便决定是否展示数据
                if (count === 1) {
                    global.constants.userid = responseJson[0].id;
                    global.constants.loginname = responseJson[0].username;//登陆名
                    global.constants.username = responseJson[0].name;//员工姓名
                    global.constants.companyid = responseJson[0].companyid;
                    global.constants.cropname1 = responseJson[0].cropname;
                    global.constants.password = responseJson[0].password;
                    global.constants.dept = responseJson[0].dept;
                    global.constants.title = responseJson[0].title;
                    global.constants.phone = responseJson[0].phone;
                    global.constants.entrydate = responseJson[0].entrydate;
                    global.constants.gender = responseJson[0].gender;
                    global.constants.homeaddr = responseJson[0].homeaddr;
                    global.constants.name = responseJson[0].name;
                    global.constants.birthday = responseJson[0].birthday;
                    global.constants.education = responseJson[0].education;
                    global.constants.ownsalesmanname = responseJson[0].name;
                    global.constants.flag1 = responseJson[0].flag1;
                    global.constants.flag2 = responseJson[0].flag2;
                    global.constants.flag3 = responseJson[0].flag3;

                    this.getSubordinate(responseJson[0].id);

                    //判断登录账号当前是否过期，以便决定是否允许登录
                    this.isExpired(responseJson[0].companyid);
                } else {
                    this.setState({
                        // notExpired:0,
                        visible: true,
                        modalcontext: '账号密码不正确，请联系管理员！',
                    })
                }

                this.setState({
                    // dataSource: responseJson
                    aa: count
                })

            })
    }

    //判断登录账号当前是否过期，以便决定是否允许登录
    isExpired(companyid) {
        let filter = {
            object: {
                object: {}
            }
        };

        var preurl = "isExpired?cropid=";
        var url = global.constants.url + preurl + companyid;
        var getInformation = {
            method: "POST",
            headers: {
                "Content-Type": "application/json"
            },
            /* json格式转换 */
            body: JSON.stringify(filter)
        }
        fetch(url, getInformation)
            .then(response => response.json())
            .then(responseJson => {
                // 返回的数据 根据自己返回的json格式取值.
                // debugger;
                // console.log('ShiheUrl0924:',url);
                // console.log('ShiheJSon0924:',responseJson);

                if (responseJson.length === 1) {
                    this.setState({
                        notExpired: 1,
                        visible: false,

                    });

                    //license账户数大于0，才能新增员工
                    // if(responseJson.licenseresidue>0){
                    //     this.addUser1(createID,memberid,parentid,supervisor,companyid,password,dept,username,title,phone,entrydate,gender,homeaddr,name,birthday,education,inputtime);
                    // }else {
                    //     this.setState({
                    //         visible: true,//让模式对话框可见
                    //     });
                    // }
                } else {
                    this.setState({
                        notExpired: 0,
                        visible: true,
                        modalcontext: '账号已过期，请联系管理员！',
                    })
                    //license账户数大于0，才能新增员工
                    // if(responseJson[0].licenseresidue>0){
                    //     this.addUser1(createID,memberid,parentid,supervisor,companyid,password,dept,username,title,phone,entrydate,gender,homeaddr,name,birthday,education,inputtime);
                    // }else {
                    //     this.setState({
                    //         visible: true,//让模式对话框可见
                    //     });
                    // }
                }
            })

    }

    //从后台获取改用户及下属的列表
    getSubordinate(userid) {
        // debugger;
        /* 查询数据的格式 */
        // var a='';
        let filter = {
            object: {
                object: {}
            }
        };

        var preurl = "getSubordinate?id=";
        var url = global.constants.url + preurl + userid;
        var getInformation = {
            method: "POST",
            headers: {
                "Content-Type": "application/json"
            },
            /* json格式转换 */
            body: JSON.stringify(filter)
        }
        fetch(url, getInformation)
            .then(response => response.json())
            .then(responseJson => {
                // 返回的数据 根据自己返回的json格式取值.
                // debugger;
                console.log('Subordinate:', responseJson);
                Subordinateid.length = 0;
                if (responseJson.length > 1) {
                    global.constants.hasteam = 1;
                } else {
                    global.constants.hasteam = 0;
                }
                // for(var i=0;i<responseJson.length;i++){
                //     Subordinateid.push(responseJson[i].id+'')  //取json中的值
                // }
                // a=responseJson[0].id;

                // global.constants.Subordinateid=Subordinateid.toString();
                // console.log('global.constants.Subordinateid:',Subordinateid);
                // console.log('SubordinateidStr11:',Subordinateid.toString());

                // this.setState({
                //
                // })

            })

    }

    handleModalOk = e => {
        console.log(e);
        this.setState({
            visible: false,
        });
    };

    handleModalCancel = e => {
        console.log(e);
        this.setState({
            visible: false,
        });
    };

    render() {
        //Form.create 包装的组件会自带this.props.form属性，该属性提供了一系列API，包括以下4个
        //getFieldDecorator用于和表单进行双向绑定
        //isFieldTouched判断一个输入控件是否经历过 getFieldDecorator 的值收集时机 options.trigger(收集子节点的值的时机，默认时onChange)
        //getFieldError获取某个输入控件的 Error
        //获取一组输入控件的 Error ，如不传入参数，则获取全部组件的 Error
        const {getFieldDecorator, getFieldsError, getFieldError, isFieldTouched} = this.props.form;
        const userNameError = isFieldTouched('username') && getFieldError('username');
        const passWordError = isFieldTouched('password') && getFieldError('password');
//validateStatus为校验状态，如不设置，则会根据校验规则自动生成，可选：'success' 'warning' 'error' 'validating'
//         if (this.state.redirect) {
//             // return <Redirect push to="/Display" />; //or <Redirect push to="/sample?a=xxx&b=yyy" /> 传递更多参数
//            return  <Link to="/Display/" ></Link>;
//         }

        if (this.state.aa && this.state.notExpired) {
            // let data1=encodeURIComponent(this.state.aa)
            // this.props.history.push(`/Login1${data1}`)

            // global.constants.showUserSide = false;

            //return <Redirect push to="/App1/Introduce"/>; //or <Redirect push to="/sample?a=xxx&b=yyy" /> 传递更多参数
            return <Redirect push to="/App1/MyWork"/>;
            // return <Redirect push to="/Display" />;
            // return  <Link to="/Display/" ></Link>;
        }

        // const onFinish = values => {
        //     console.log('Success:', values);
        // };
        //
        // const onFinishFailed = errorInfo => {
        //     console.log('Failed:', errorInfo);
        // };


        return (
            <div>
                <div>
                    {/*<div className="wenzi">*/}
                    {/*时和销售CRM系统*/}
                    {/*</div>*/}
                    <div className="box">
                        {/*<div>*/}
                            {/*<img className="imgwidth" src={require("./shihe.png")} alt=""/>*/}
                        {/*</div>*/}
                        <div className="wenzi">>
                            <p>CRM软件</p>
                        </div>

                    </div>

                    <Form onSubmit={this.handleSubmit}
                          {...layout}
                          name="basic"
                          // initialValues={{
                          //     remember: true,
                          // }}
                          // onFinish={onFinish}
                          // onFinishFailed={onFinishFailed}
                    >
                        <div className="denglu" style={{ textAlign: 'center' }}>登录</div>

                        <Form.Item label="用户名" validateStatus={userNameError ? 'error' : ''}>
                            {getFieldDecorator('username', {
                                rules: [{required: true, message: '请输入您的用户名!'}],
                            })(
                                <Input
                                    prefix={<Icon type="user" style={{color: 'rgba(0,0,0,.25)'}}/>}
                                    placeholder="用户名"
                                />,
                            )}
                        </Form.Item>

                        <Form.Item label="密码" validateStatus={passWordError ? "error" : ''}>
                            {getFieldDecorator('password', {
                                rules: [{required: true, message: '请输入您的密码!'}],
                            })(
                                <Input
                                    prefix={<Icon type="lock" style={{color: 'rgba(0,0,0,.25)'}}/>}
                                    type="password"
                                    placeholder="密码"
                                />,
                            )}
                        </Form.Item>




                        <Form.Item {...tailLayout}>

                            {/*{getFieldDecorator('remember', {*/}
                            {/*valuePropName: 'checked',*/}
                            {/*initialValue: true,*/}
                            {/*})(<Checkbox>记住我</Checkbox>)}*/}

                            {/*<a className="login-form-forgot" href="">*/}
                            {/*忘记密码*/}
                            {/*</a>*/}

                            <Button type="primary" htmlType="submit" className="login-form-button">
                                立即登录
                            </Button>
                            {/*<a href="">立即注册</a> <a href="">忘记密码</a>*/}
                            <Link to={"/Register1"}>去注册</Link>
                            <Link to={"/"} style={{ marginLeft: 10 }}>去主页</Link>
                        </Form.Item>
                        {/*{this.state.username}:{this.state.password}*/}
                        {/*{this.state.aa}*/}

                        {/*<Link to="/Display/" style={{color:'black'}}>*/}
                        {/*<div>点击跳转到主页</div>*/}
                        {/*</Link>*/}

                    </Form>

                    {/*<a href="http://www.miitbeian.gov.cn/">粤ICP备18079338号-1</a>*/}


                </div>

                <div className="footer" style={{ textAlign: 'center' }}>
                    {/*<div>联系QQ：260815998</div>*/}
                    {/*<div>联系E-mail：260815998@qq.com</div>*/}
                    {/*<div>业务咨询：0755-61300562</div>*/}
                    {/*<div>客服微信：lihaiming0755，E-mail：lihaiming@dreamsaler.com</div>*/}
                    {/*<a href="http://www.beian.miit.gov.cn/">ICP证号：粤ICP备19140753号</a>*/}
                    <div>©2019-2024 深圳市时和科技有限公司</div>
                </div>


                <div>
                    <Modal
                        title="提示"
                        visible={this.state.visible}
                        onOk={this.handleModalOk}
                        onCancel={this.handleModalCancel}
                        okText="确定"
                        cancelText="取消"
                    >
                        <p>{this.state.modalcontext}</p>
                    </Modal>
                </div>
            </div>


        );
    }

}

// const WrappedRegistrationForm = Form.create()(Login);
const Login1 = Form.create({name: 'normal_login'})(Login);
export default Login1;
