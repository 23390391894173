import React from'react';
import {Button} from 'antd';
import Loadable from 'react-loadable';
// import AsyncApp from './index';
import Home from './Home';

//第二种代码分割方法
const MyLoadingComponent = ({isLoading, error}) => {
    // Handle the loading state
    if (isLoading) {
        return <div className="wenzi4">页面加载中，请稍等...</div>;
    }
    // Handle the error state
    else if (error) {
        return <div className="wenzi4">页面加载中，请稍等...</div>;
    }
    else {
        return null;
    }
};


export const AsyncApp = Loadable({
    loader: () => import("./App"),
    loading: MyLoadingComponent
    // delay: 300, // 0.3 seconds
});

const AsyncHome = Loadable({
    loader: () => import("./Home"),
    loading: MyLoadingComponent
    // delay: 300, // 0.3 seconds
});

class HomeKe extends React.Component{
    constructor(props){
        super(props)
    }

    componentWillMount(){
    //服务器渲染的唯一hook
    }

    componentDidMount(){
    //可以调用setState，renderComponent
        AsyncHome.preload();
        AsyncApp.preload();
    }

    render(){
        return(
            <AsyncHome/>

        );
    }
}

export default HomeKe
