import React from "react";

global.constants = {
    count1:0, //用全局变量传递登录验证返回的合法账号查询到的数量

    Subordinateid:'', //当前登录用户及其下属的id

    userid:0, //以下是登录用户user表所有字段
    username:'',//姓名
    loginname:'',//登录名
    companyid:0,
    cropname1:'CRM软件',//从登陆获得的
    password:0,
    dept:'',
    title:'',
    phone:'',
    entrydate:'',
    gender:'',
    homeaddr:'',
    name:'',
    birthday:'',
    education:'',
    ownsalesmanname:'',
    tempname:'好人',//销售员姓名
    tempendday:'',
    flag1:'',//流程标志1
    flag2:'',//流程标志2
    flag3:'',//流程标志3

    editorHtmlFollow:'',//客户跟进内容

    moduleid:0,
    // moduleidlist:[],
    // showUserSide:false,//是否展示左侧导航栏的"员工管理"
    auths:[], //后台查询回来的某用户的模块权限
    // boxCheckedValues:0, //勾选的多选框的值,一串数字
    arr:[1,2],//勾选的多选框的值,分隔数字，用数组表示
    clickedRoleId:1,//当前点击的角色ID，默认为1
    clickedrolename:'管理员',//当前点击的角色名，默认为"管理员"
    test:0,
    rmexist:0,//该"角色-模块"记录是否存在
    ModuleAuth:[],//  //获得角色roleid的权限模块
    length1:0,
    restarr1:[],//未被勾选的多选框权限模块ID
    selectedrolemember:[], //已选择的角色成员的id
    rolemember:[],//数据库保存的角色成员
    user1List:null,//改公司的所有员工，getUser1ListByCompanyid的返回，Member模块增删改员工时刷新，RoleMember穿梭框模块调用

    isGetCustomerByInputtime:0,//是否正在按创建客户时间查询,通用
    isGetCustomerzxByInputtime:0,//是否正在按创建客户时间查询,装修客户
    isCustomerName:1,//客户名下拉菜单是1，开票类型下拉菜单是0
    supervisorid:'',//直接上级的ID

    order:[],//订单
    ordershuliang:0,//订单数量
    clickedOrdernum:0,//点击的订单编号，以便添加商品
    cropname:'',//公司名
    ordertotalamount:0, //订单总额
    ordertime:'',//下单时间
    returnedmoney:0,//已回款金额
    unreturnedmoney:0,//未回款金额，修改订单产品后，触发重新计算该项
    recordOpen:{},//点击开票计划时的订单记录
    kaipiaoPlan:[],//开票计划
    kfwx:{},//点击'客返维修明细'是的客返记录
    kfwxcp:[],//点击时，客返维修产品明细

    hasteam:1,//是否有团队

    licenseamount:0,//账号总数
    licenseused:0,//已使用数
    licenseresidue:0,//剩余个数
    ratiolicense:0,//剩余账号比例

    workreportuserid: null,//工作报告-用户id
    workreportname: null,//工作报告-用户名
    workreportaddwhat: null,//工作报告-新增日报等
    workreporttype:null,//工作报告类型
    workreporttitle:null,//工作报告标题
    workreportcontent:null,//工作报告内容
    workreportstartdate:null,//工作报告开始日期
    workreportenddate:null,//工作报告结束日期
    workreportdate:null,//工作报告日期
    workreporttypeupdate:null,//修改的工作报告类型
    workreportidupdate:null,//修改的工作报告id

    isworkreportupdate:false,//是否是修改报告
    isworkreportadd:false,//是否是新增报告

    visitCompanyOrPerson:'',//拜访的对方公司或个人
    visituserid: null,//点击的拜访者id
    visitname: null,//点击的拜访者姓名
    vistaddwhat: null,//张三-新增拜访总结，
    vistcustomername: null,//拜访的客户公司名称
    vistcustomerid: null,//拜访的客户公司ID
    vistIndex1: null,//选择的打卡记录index
    vistsigntimestr: null,//选择的打卡记录,时间+地址
    visitdakalist:[],//打卡列表
    isvistadd: false,//是否是新增拜访总结
    isvistupdate: false,//是否是更新拜访总结
    visitidupdate: null,//要修改的拜访总结ID
    visitcontent:null,//拜访总结的内容
    visitcustomerlist:null,//客户列表
    visitaddr:null,//addr，update时使用
    visitdest:null,//dest，update时使用
    visitinputtime:null,//inputtime,update时使用
    visitsigntime: null,//visitsigntime,update时使用

    privatereportaddwhat:null,//张三-新增私人笔记
    isprivatereportadd: false,//是否是新增私人笔记
    isprivatereportupdate: false,//是否是修改私人笔记
    privatereporttitle: null,//私人笔记标题
    privatereportdate: null,//私人笔记日期
    privatereportcontent: null,//私人笔记内容
    privatereportid: null,//修改的私人笔记ID

    isInfoMgtadd: false,//是否是新增
    isInfoMgtupdate: false,//是否修改
    infomgttitle:'',//信息收集标题
    infomgtcontent:'',//信息收集内容
    infomgtid:'',//信息收集ID

    vistplanaddwhat: null,//张三-新增外勤拜访计划
    isvisitplanadd: false,//是否是新增拜访计划
    isvisitplanupdate: false,//是否是更新拜访总结
    visitplanidupdate: null,//拜访计划ID
    visitPlanIndex1: null,//拜访计划index
    visitPlanDate: null,//拜访日期
    visitPlanTime: null,//拜访时间
    visitAddr: null,//拜访地址
    visitObj:null,//拜访对象
    visitPhone:null,//拜访联系电话
    visitMark:null,//拜访备注
    vistplanlist:[],//查询回来的某个人的拜访计划列表

    leadprivatereportaddwhat: null,//张三-新增销售线索
    isleadadd: false,//是否是新增销售线索
    isleadaddsub: false,//是否是新增销售线索子节点
    isleadupdatetitle: false,//是否是更新销售线索标题
    isleadupdatesub: false,//是否是更新销售线索子节点
    leadsignleid:null,//单个子节点ID
    leadid:null,
    leaddate: null,//销售线索节点日期
    leadtitle: null,//销售线索标题
    leadcontent: null,//销售线索节点内容
    leadcropname:null,
    leadclickeduserid:null,//点击线索title对应的userid

    fieldwhat:null,//模式框的标题，新增字段
    isfieldadd: false,//是否是新增字段 for structure
    isfieldtitleadd: false,//是否是新增字段标题 for structure
    isfieldvalueadd: false,//是否是新增字段值 for structure
    isfieldtitleupdate: false,//是否是修改字段标题 for structure
    isfieldvalueupdate: false,//是否是修改字段值 for structure
    fieldtitle: null,//字段标题 for structure
    fieldvalue: null,//字段值
    fieldvaluelistid:null,//id,一组自定义字段名和值的id
    fieldvaluestructureid: null,// =客户公司的id,整个structure的id
    single1: null,
    fieldnamelist:[],
    selfinputvalue:'',//自定义字段，无子项的input输入
    selectedfield: null,//下拉框选择的字段
    selectedfieldIndex: 0,//下拉框选择的字段在fieldvalueListmongo[]的下标
    // fielddataSource:[],
    fieldcolumns:[],
    isupdateinput:false,//是否更新字段值 for input
    rowkey:null,//即row[key]
    rowid:null,
    rowfieldnameid:null,
    fieldcolumnwidth: null,//表格总宽度

    processCheck: '查看流程',//查看流程
    processUpdate: '修改流程',//修改流程
    processAdd: '新增流程',//新增流程
    addProduct: '添加产品',//添加订单产品
    clickAddProduct: 0,//点击了订单-添加产品
    // isprocessAdd: 0,//是否是新增流程
    // isprocessUpdate: 0,//是否是更新流程
    processid: '123',
    clickProcessCancel: 0,//点击了关闭流程弹窗
    clickProcessUpdate: 0,//点击过更新流程
    clickProcessAdd: 0,//点击了新增流程
    processCheckWei: '查看未完结流程',//查看未完结流程
    processCheckYi: '查看已完结流程',//查看已完结流程
    processCopytoCheckWei: '查看抄送给我的未完结流程',//查看抄送给我的未完结流程
    processCopytoCheckYi: '查看抄送给我的已完结流程',//查看抄送给我的已完结流程
    clickprocessCheckWei: 0,//点击过查看未完结弹窗
    clickprocessCheckYi: 0,//点击过查看已完结弹窗
    clickprocessCopytoCheckWei: 0,//点击过查看抄送给我的未完结弹窗
    clickprocessCopytoCheckYi: 0,//点击过查看抄送给我的已完结弹窗
    clickProcname:'',//点击查看流程记录时的流程名称
    getclaimableGroupTaskluserid:'',//获取可认领任务组的人userid
    processUpdateRecord:'',//被修改的流程record
    instidinwarehouse:'',//入库页面，下拉选择采购单对应的实例ID
    clickedOutwarehouse: 0,//正在进行出库审批
    selectedProcessinstanceId:'',//我的工作-待办任务-点击"审批"
    startuserid:'',//流程发起人的userID

    visitstarttime:'',//拜访开始时间
    visitendtime:'',//拜访结束时间
    nextvisittime:'',//下次拜访时间
    signtime:'',//打卡时间
    inputValueDest:'',//拜访目的地
    selecteddakaaddr:'',//选择的拜访地址

    distributeVisible: false,//公海客户分配
    supplierProduct:[],//采购申请，切换供应商，对应的供应商产品
    oldfileList:[],//流程附件信息表，格式与fileList不一样

    // clickProcessDeployment: 0,
    // clickProcessAdd: 0,//点击了新增流程
    // fieldvaluename:null,//自定义字段值
    // url: 'https://www.dreamsaler.cn/',//域名地址的前缀,常用
    // url: 'http://localhost:8080/',//本地地址的前缀
    // url: 'http://www.dreamsaler.cn/',//域名地址的前缀,常用
    // url: 'http://www.dreamsaler.cn:8080/api/',//域名地址的前缀,常用
    // url: 'http://www.dreamsaler.cn/html/',
    // url: 'http://www.dreamsaler.cn/html/',
    // url: 'http://www.dreamsaler.cn:3000/html/',
    // url: 'http://localhost:3000/api/',
    //   url: 'http://localhost:8080/api/', //本地，有用
    // url: 'https://www.dreamsaler.cn/api/', //云端，有用
    url: 'https://www.dreamsaler.com/api/', //云端，有用
    // url: 'http://101.133.237.85:8080/api/', //云端
};
